import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const ns = `past-exhibiton-card`

const PastExhibitionCard = ({ pastexhibitioncard }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={`${rootClassnames} row align-items-center`}>
      <div className="offset-md-1 col-md-5 ">
        <p className="event--date">{pastexhibitioncard.date}</p>
        {pastexhibitioncard.url === '#' ? (
          <h2 className="event--title">{pastexhibitioncard.title}</h2>
        ) : (
          <Link to={pastexhibitioncard.url}>
            <h2 className="event--title">{pastexhibitioncard.title}</h2>
          </Link>
        )}
      </div>
      <div className="col-md-5 padding-right-0">
        {pastexhibitioncard.url === '#' ? (
          <img
            className="img-fluid"
            src={pastexhibitioncard.imageUrl}
            alt={pastexhibitioncard.title}
          />
        ) : (
          <Link to={pastexhibitioncard.url}>
            <img
              className="img-fluid"
              src={pastexhibitioncard.imageUrl}
              alt=""
            />
          </Link>
        )}
      </div>
      <div className="offset-md-1 col-md-10 border-bottom-black" />
    </div>
  )
}

export default PastExhibitionCard

PastExhibitionCard.defaultProps = {
  pastexhibitioncard: {
    date: 'OCTOBER 2019–FEBRUARY 2020',
    title: 'RODNEY MCMILLIAN, BROWN: VIDEOS FROM THE BLACK SHOW',
    imageUrl: '/pastexhibitions/2019_10_08_UM_Rodney_McMillian_22@2x.png',
    url: '/past-exhibitions',
  },
}
