import React from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero/Hero'
import PastExhibitionCard from '../components/PastExhibitonCard'

const ns = `pastexhibitons-page`

const PastExhibitonsPage = ({ data }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const {
    markdownRemark: { frontmatter: content },
  } = data

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile: content.hero.hero_image_mobile.publicURL,
            urlDesktop: content.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{content.title}</h1>
          <div className={`${ns}__content-container container-fluid`}>
            {content.exhibitions.map(exhibition => (
              <PastExhibitionCard
                pastexhibitioncard={{
                  date: exhibition.date,
                  title: exhibition.exhibition_title,
                  imageUrl: exhibition.featured_image.publicURL,
                  url: exhibition.exhibition_url,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PastExhibitonsPage

export const pastExhiPageQuery = graphql`
  query PastExhiPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        exhibitions {
          date
          exhibition_title
          featured_image {
            publicURL
          }
          exhibition_url
        }
      }
    }
  }
`
