import React from 'react'
import classNames from 'classnames'

const ns = `hero`

const Hero = ({ hero }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__container`}>
        <picture>
          <source media="(min-width: 768px)" srcSet={hero.urlDesktop} />
          <img src={hero.urlMobile} alt="" />
        </picture>
      </div>
      {hero.credit && (
        <div className={`${ns}__container-text`}>
          <p className="credit">{hero.credit}</p>
        </div>
      )}
    </div>
  )
}

export default Hero

Hero.defaultProps = {
  hero: {
    urlDesktop: '/visitus/hero.png',
    urlMobile: '/visitus/hero.png',
    credit:
      'Eduardo Sarabia walks through his work “The Passenger,” Lorem ipsum dolor sit amet. Photography credit: Minyoung Sohn. Courtesy of the Judelson Family Foundation.',
  },
}
